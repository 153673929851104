import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

import ReactPlayer from 'react-player'
import ImageGallery from 'react-image-gallery'

import image1 from '../images/1.jpg'
import image2 from '../images/2.jpg'
import image3 from '../images/3.jpg'
import image4 from '../images/4.jpg'
import image5 from '../images/5.jpg'

const images = [
    {
      original: image1,
    },
    {
      original: image2,
    },
    {
      original: image3,
    },
    {
      original: image4,
    },
    {
      original: image5,
    },
  ]

const Help2019Page = () => (
  <Layout>
    <div id="wrapper">
      <ul>
        <li><Link to="/" className="button ">Nazad</Link></li>
      </ul>
        <article
          id="pomoc"
          className="pomoc"
        >
          <h2 className="major">Pomoć</h2>
          <p>
            Zavod za psihofiziološke poremećaje i govornu patologiju "Profesor
            dr Cvetko Brajović" u Beogradu je jedina ustanova te vrste, ne samo
            u Srbiji već i u regionu. Zavod ima i svoj stacionar u Lipovici, u
            kojem deca borave zajedno sa roditeljima, dok se ne završi
            dijagnostika i, tokom rehabilitacije.
          </p>
          <p>
            Zavod je specijalizovana zdravstvena ustanova koja se bavi
            prevencijom, dijagnostikom, habilitacijom i rehabilitacijom dece i
            odraslih osoba sa različitim teškoćama u psihofiziološkom i
            govorno-jezičkom funkcionisanju.{' '}
          </p>
          <p>
            Godišnje kroz Zavod prođe oko 15.000 pacijenata. Međutim, ovoj
            ustanovi za savremeni rad sa pacijentima, nedostaje mnogo toga.{' '}
          </p>
          <p>
            Pored savremenih sredstava za terapiju, deci koja tamo boravi,
            nedostaju i osnovni uslovi za život i boravak dok su na lečenju. Od
            klima uređaja kako leti ne bi spavali na temperaturi od 40+ stepeni,
            do grejanja zimi da se ne smrzavaju.{' '}
          </p>
          <p>
            Više informacija u RTS reportaži:
            <br />
          </p>
          <ReactPlayer
            url="http://www.rts.rs/upload/storyBoxFileData/2014/09/10/5169917/Stacionaru%20u%20Lipovici%20neophodna%20pomoc%20100914.mp4"
            volume={1}
            muted
            width="100%"
            height="100%"
            controls
          />
          <br />
          <ImageGallery
            items={images}
            showPlayButton={false}
            showThumbnails={false}
            showFullscreenButton={false}
            disableArrowKeys={true}
          />
          <br />
          <p>
            Ideja je da ovim turnirom krenemo u prikupljanje sredstava koje ćemo
            donirati Stacionaru Lipovica.
          </p>
          <p>Budimo humani.</p>
        </article>
    </div>
  </Layout>
)

export default Help2019Page
